import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';
import RoutePublic from './RoutePublic';
import ProposalPartReq from '../pages/public/ProposalPartReq';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <RoutePublic path="/proposta" component={ProposalPartReq} />
      </Switch>
    </BrowserRouter>
  );
}
