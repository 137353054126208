import React from 'react';
import { Result } from 'antd';

export default function NotFoundProposal() {
  return (
    <Result
      status="404"
      title="Proposta não encontrada"
      subTitle="Houve um erro ao carregar esta proposta ou ela foi removida pela
            empresa."
    />
  );
}
