import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: 0;
        outline: 0;
    }

    body {
        font: 400 14px Roboto, sans-serif; /* Se a font roboto der problema, ele usa sans-serif */
        -webkit-font-smoothing: antialiased;
        color: #312e38;
    }

    input, textarea {
        font: 400 14px Roboto, sans-serif;
        background-color: #FFF;
    }

    button {
        cursor: pointer;
        background-color: #3B72FF;
        color: "#FFF";
    }

    form input {
        width: 90%;
        height: 35px;
        color: #333;
        border: 1px solid #AAA;
        border-radius: 6px;
        padding: 0 10px;
        margin-bottom: 6px;
        }

    a {
        color: #001029;
        font-weight: 400;
        font-size: 14;

    }

    ::-webkit-scrollbar {
      width: 15px;
      height: 10px;
    }

    ::-webkit-scrollbar-button:start:decrement,
    ::-webkit-scrollbar-button:end:increment  {
      display: none;
    }

    ::-webkit-scrollbar-track-piece  {
      background-color: #DDD;
      -webkit-border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:vertical {
      background-color: #3B72FF;
      -webkit-border-radius: 2px;
    }

    //Necessário para usar tailwind com antd
    img {
      display: unset !important;
    }
`;
