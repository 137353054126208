import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';

export default function LoaderProposal() {
  return (
    <Result
      title="Carregando Proposta"
      icon={<LoadingOutlined style={{ fontSize: 48 }} spin />}
    />
  );
}
