import { message } from 'antd';

function feedbackSuccess(msg = 'Sucesso', seconds = 1.5) {
  message.success(msg, seconds);
}

function feedbackError(msg = 'Erro', seconds = 1.5) {
  message.error(msg, seconds);
}

function feedbackWarning(msg = 'Atenção', seconds = 1.5) {
  message.warning(msg, seconds);
}

export { feedbackSuccess, feedbackError, feedbackWarning };
