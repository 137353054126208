/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import RowPartRequisition from '../RowPartRequisition';
import StatusApprove from './components/StatusApprove';
import { IProposal } from '../../../../../@types/Os/IProposal';

interface IProps {
  proposal: IProposal;
  posSave(id: string): Promise<void>;
}

export default function PartRequisition(props: IProps) {
  const [subTotal, setSubTotal] = useState<string>('');

  const { proposal, posSave } = props;

  useEffect(() => {
    somaTotal();
  }, [proposal]);

  async function somaTotal() {
    let vtotal = '0';

    setSubTotal(vtotal);

    proposal?.part_req?.map(function (req) {
      //Se o valor da req estiver em branco, não entra na soma
      if (req.valor_total) {
        const numero1 = parseFloat(vtotal);
        const numero2 = parseFloat(req.valor_total);

        vtotal = (numero1 + numero2).toFixed(2);

        setSubTotal(vtotal);
      }
    });
  }

  return (
    <>
      {proposal?.part_req?.length > 0 ? (
        <div className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Requisição de peças
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Foi constatada a necessidade dos itens abaixo para a realização
                do serviço. <br />
                <span className="text-xs text-gray-500 font-extralight">
                  Clique no botão para aceitar a proposta abaixo.
                </span>
              </p>
            </div>

            <StatusApprove proposal={proposal} posSave={posSave} />
          </div>
          <div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    Descrição do item
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Quant.
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Valor unit.
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                  >
                    Total item
                  </th>
                </tr>
              </thead>
              <tbody>
                {proposal?.part_req?.map(req => (
                  <RowPartRequisition part_req={req} key={req.id_req_peca} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                  >
                    Total
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                  >
                    Total
                  </th>
                  <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                    R$ {subTotal}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
}
