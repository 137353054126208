/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import PartRequisition from './components/PartRequisition';
import ConvertDateTimeToString from '../../../functions/ConvertDateTimeToString';
import { IProposal } from '../../../@types/Os/IProposal';
import LoaderProposal from './components/LoaderProposal';
import NotFoundProposal from './components/NotFoundProposal';

export default function ProposalPartReq() {
  const [proposal, setProposal] = useState<IProposal | null>();
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const id_proposta = params.get('id');

  useEffect(() => {
    if (id_proposta) getProposal(id_proposta);
  }, [id_proposta]);

  async function getProposal(id: string) {
    if (!id) return;

    setLoading(true);

    try {
      const response = await api.get(`os/proposal/${id}`);

      setProposal(response.data);
    } catch (err) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  return (
    <div className="min-h-full">
      <div className="lg:pl-64 flex flex-col flex-1">
        <main className="flex-1 pb-8">
          <div className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow-2xl sm:rounded-xl">
                  {loading ? (
                    <LoaderProposal />
                  ) : (
                    <>
                      {!proposal || proposal?.status !== 'enabled' ? (
                        <NotFoundProposal />
                      ) : (
                        <>
                          <div className="px-4 py-5 sm:px-6">
                            <h2
                              id="applicant-information-title"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              {proposal?.customer?.edificio}
                            </h2>
                            <p className="mt-1 max-w-2xl text-xs font-normal text-gray-900">
                              Elevador: {proposal?.equipment?.apelido}
                            </p>
                            <p className="max-w-2xl text-xs text-gray-500">
                              OS: {proposal?.os?.id_os}
                            </p>
                            <p className="max-w-2xl text-xs text-gray-900">
                              {proposal?.resume}
                            </p>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                  Solicitante
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {proposal?.os?.chamado
                                    ? proposal?.os?.solicitante
                                    : `Elite`}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                  Data de Solicitação
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {ConvertDateTimeToString(
                                    proposal?.os?.created_at,
                                  )}
                                </dd>
                              </div>
                              {proposal?.os?.chamado ? (
                                <>
                                  <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                      Defeito relatado
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                      {proposal?.os?.descricao}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                      Status do chamado
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 capitalize">
                                      {proposal?.os.status}
                                    </dd>
                                  </div>
                                  {proposal?.os?.status === 'concluido' ? (
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Data de conclusão
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {ConvertDateTimeToString(
                                          proposal?.attendances[0].data_saida,
                                        )}
                                      </dd>
                                    </div>
                                  ) : null}
                                  {proposal?.os.status === 'concluido' ? (
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Técnico responsável
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {
                                          proposal?.attendances[0]
                                            .id_funcionario_resp
                                        }
                                      </dd>
                                    </div>
                                  ) : null}
                                  {proposal?.os.status === 'concluido' ? (
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Cliente atendeu
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {proposal?.attendances[0].cliente_resp}
                                      </dd>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              <div className="sm:col-span-2 border-t-2 pt-2">
                                <PartRequisition
                                  proposal={proposal}
                                  posSave={getProposal}
                                />
                              </div>
                            </dl>
                          </div>
                          {/* <div>
                    <a
                      href="#"
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                    >
                      ...
                    </a>
                  </div> */}
                        </>
                      )}{' '}
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
