import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './contexts/Auth';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import GlobalStyle from './styles/global';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <AuthProvider>
        <Routes />
      </AuthProvider>

      <GlobalStyle />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
