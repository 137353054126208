/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import api from '../../../../../../../services/api';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../../../functions/MessageFeedback';
import ModalConfirmApprove from '../ModalConfirmApprove';
import { IProposal } from '../../../../../../../@types/Os/IProposal';

interface IProps {
  proposal: IProposal;
  posSave(id: string): Promise<void>;
}

export default function StatusApprove(props: IProps) {
  const [open, setOpen] = useState(false);
  const [submeting, setSubmeting] = useState(false);

  const { proposal, posSave } = props;

  async function approveOrder(note: string): Promise<void> {
    setSubmeting(true);

    const data = {
      id_proposal: proposal.id,
      note,
    };

    try {
      await api.post(`os/proposal/approve`, data);

      feedbackSuccess('Orçamento aprovado com sucesso!');
    } catch (err) {
      feedbackError('Ocorreu um erro. Caso persista, contate o suporte.');
    }

    await posSave(proposal.id);

    setSubmeting(false);
  }

  return (
    <>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        {proposal?.os?.approved ? (
          <button
            type="button"
            disabled={true}
            className="inline-flex items-center justify-center rounded-sm border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
          >
            Aprovado
          </button>
        ) : (
          <button
            type="button"
            disabled={submeting}
            onClick={() => setOpen(true)}
            className="inline-flex items-center justify-center rounded border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          >
            Aprovar
          </button>
        )}
      </div>
      <ModalConfirmApprove
        approveOrder={approveOrder}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
